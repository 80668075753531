import { StyledPaper } from '@groupbuilderoy/gb-components-library';
import Form from 'components/Form/Form';
import { FormState, FieldType, HtmlInputState } from 'components/Form/interfaces';
import {
  SectionType,
  ICustomContentSection,
  ISection,
  SectionSettings,
  Attachment
} from 'components/Sections/Section';
import { PhaseContext } from 'context/Phase/PhaseContext';
import { SectionContext } from 'context/Section/SectionContext';
import useGetPreviewTags from 'hooks/useGetPreviewTags';
import useT from 'hooks/useT';
import { useContext } from 'react';

interface SectionFormProps {
  section?: ISection;
  onSubmit: (_section: ISection, _attachments?: Attachment[]) => Promise<ISection>;
}

export default function SectionForm({ section, onSubmit }: SectionFormProps) {
  const { sectionState, dispatch } = useContext(SectionContext);
  const { phaseState } = useContext(PhaseContext);
  const t = useT();
  const settings = sectionState.settings as SectionSettings;
  const { maxNameLength } = settings.validation;
  const { appendCustomContent } = useGetPreviewTags();

  const sectionTypeOptions = [
    {
      value: SectionType.CUSTOM_CONTENT,
      name: 'Custom content'
    }
  ];

  const onSectionTypeSelected = (type: string, formState: FormState) => {
    switch (Number(type)) {
      case SectionType.CUSTOM_CONTENT: {
        if (formState.html) return formState;
        const customContentSection = section as ICustomContentSection;

        formState['html'] = {
          value: customContentSection?.content || '',
          required: true,
          validationErrorKey: 'validation.fillContent',
          type: FieldType.HTML_CONTENT,
          localizationKey: 'newSection.htmlContent',
          preview: customContentSection?.preview,
          attachments: customContentSection?.attachments || []
        } as HtmlInputState;

        return formState;
      }
    }
  };

  const initialFormState = {
    name: {
      value: section?.name || '',
      required: true,
      validationErrorKey: 'validation.fillName',
      type: FieldType.STRING,
      localizationKey: 'newSection.name',
      maxLength: maxNameLength
    },
    width: {
      value: String(section?.width || '1'),
      required: true,
      validationErrorKey: 'validation.fillWidth',
      type: FieldType.SELECT,
      localizationKey: 'newSection.width',
      options: [
        {
          value: 1,
          name: `1 ${t('form.column')}`
        },
        {
          value: 2,
          name: `2 ${t('form.column')}`
        },
        {
          value: 3,
          name: `3 ${t('form.column')}`
        }
      ]
    },
    type: {
      value: '',
      defaultValue: sectionTypeOptions.length === 1 && sectionTypeOptions[0].value,
      required: true,
      validationErrorKey: 'validation.fillSectionType',
      type: FieldType.SELECT,
      options: sectionTypeOptions,
      localizationKey: 'newSection.sectionType',
      onSelectOption: onSectionTypeSelected,
      disabled: sectionTypeOptions.length === 1
    }
  } as FormState;

  const onSubmitForm = async (formState: FormState) => {
    const { name, width, type, html } = formState;
    const sectionToSubmit: ISection = {
      id: Number(section?.id),
      name: String(name.value),
      width: Number(width.value),
      type: Number(type.value),
      phaseId: Number(phaseState.selectedPhase?.id)
    };

    if (Number(type.value) === SectionType.CUSTOM_CONTENT) {
      (sectionToSubmit as ICustomContentSection).content = appendCustomContent(
        String(html.value),
        settings.previewContentTag
      );
      (sectionToSubmit as ICustomContentSection).attachments = (html as HtmlInputState).attachments;
    }

    await onSubmit(sectionToSubmit);
  };

  const updateSections = () => {
    dispatch({
      ...sectionState,
      update: true
    });
  };

  return (
    <StyledPaper style={{ width: '100%' }}>
      <Form
        localizationKeys={{
          title: section ? 'editSection.title' : 'newSection.title',
          submit: section ? 'update' : 'create',
          submitSuccess: section
            ? 'form.editSection.updateSuccess'
            : 'form.newSection.creationSuccess',
          submitError: section ? 'form.editSection.updateFailed' : 'form.newSection.creationFailed'
        }}
        fields={initialFormState}
        onBackButtonClick={updateSections}
        onSubmit={onSubmitForm}
      />
    </StyledPaper>
  );
}
