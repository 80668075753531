import { getForms } from 'axiosInstances';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from 'context/App/AppContext';
import { ProjectContext } from 'context/Project/ProjectContext';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { FormsSection } from 'components/Sections/Forms/FormsAccordions/interfaces';
import { FormsContext } from 'context/Forms/FormsContext';

const useGetFormsSection = (sectionId: string) => {
  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { apartmentState } = useContext(ApartmentContext);
  const { selectedProject } = projectState;
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { formsState, dispatch } = useContext(FormsContext);

  const fetch = async () => {
    if (!sectionId || projectState.loading || apartmentState.loading) return;

    try {
      const { organisationId, tenantId } = appState.startParameters;

      const formsSection = await getForms({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: appState.token as string,
        projectId: String(selectedProject?.id),
        sectionId: String(sectionId),
        apartmentId: String(apartmentState.selectedApartment?.id)
      });

      dispatch({
        ...formsState,
        formsSection: formsSection as FormsSection
      });
    } catch (e) {
      setError(true);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [apartmentState.loadDetails]); // eslint-disable-line

  return {
    loading,
    error
  };
};

export default useGetFormsSection;
