import {
  editUserDetail,
  getUserDetail,
  getUserDetailPicture,
  postUserDetailPicture
} from 'axiosInstances';
import { AppContext } from 'context/App/AppContext';
import { useContext, useEffect, useState } from 'react';
import { UserDetailsFormData } from 'routes/UserDetails/UserDetails';

const useGetUserDetails = () => {
  const [userDetail, setUserDetail] = useState<UserDetailsFormData>({
    auth0Id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    city: '',
    stateProvince: '',
    postCode: '',
    country: '',
    profilePicture: null
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState<any>();
  const { appState } = useContext(AppContext);

  const { organisationId, tenantId } = appState.startParameters;

  const fetch = async () => {
    try {
      if (!appState.token || !appState.user) {
        return;
      }
      setLoading(true);
      setUser(user);
      const res = await getUserDetail({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: String(appState.token),
        auth0Id: String(appState.user?.auth0Id)
      });
      const userDetailData: UserDetailsFormData = {
        auth0Id: res.auth0Id ?? '',
        firstName: res.firstName ?? '',
        lastName: res.lastName ?? '',
        email: res.email ?? '',
        phoneNumber: res.phoneNumber ?? '',
        streetAddress: res.streetAddress ?? '',
        city: res.city ?? '',
        stateProvince: res.state ?? '',
        postCode: res.zipCode ?? '',
        country: res.country ?? '',
        profilePicture: ''
      };
      if (res.picture) {
        const imageBlob = await getUserDetailPicture({
          organisationId: String(organisationId),
          tenantId: String(tenantId),
          gbToken: String(appState.token),
          auth0Id: String(appState.user?.auth0Id)
        });
        const imageObjectURL = URL.createObjectURL(imageBlob);
        userDetailData.profilePicture = imageObjectURL;
      }
      setUserDetail(userDetailData);
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const uploadProfilePicture = async (file: File) => {
    try {
      if (!appState.token) {
        return;
      }
      await postUserDetailPicture({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: String(appState.token),
        auth0Id: String(appState.user?.auth0Id),
        file
      });
    } catch (error) {
      console.error('Error uploading profile picture', error);
      setError(true);
    }
  };

  const updateUserDetails = async (bodyData: UserDetailsFormData) => {
    try {
      if (!appState.token || !appState.user) {
        return;
      }
      const updateData = { ...bodyData };
      delete updateData.profilePicture;
      const res = await editUserDetail({
        organisationId: String(organisationId),
        tenantId: String(tenantId),
        gbToken: String(appState.token),
        auth0Id: String(appState.user?.auth0Id),
        email: String(appState.user?.email),
        userDetailData: updateData
      });
      const userDetailData: UserDetailsFormData = {
        auth0Id: res.auth0Id ?? '',
        firstName: res.firstName ?? '',
        lastName: res.lastName ?? '',
        email: res.email ?? '',
        phoneNumber: res.phoneNumber ?? '',
        streetAddress: res.streetAddress ?? '',
        city: res.city ?? '',
        stateProvince: res.state ?? '',
        postCode: res.zipCode ?? '',
        country: res.country ?? '',
        profilePicture: res.picture ?? ''
      };
      if (res.picture) {
        const imageBlob = await getUserDetailPicture({
          organisationId: String(organisationId),
          tenantId: String(tenantId),
          gbToken: String(appState.token),
          auth0Id: String(appState.user?.auth0Id)
        });
        const imageObjectURL = URL.createObjectURL(imageBlob);
        userDetailData.profilePicture = imageObjectURL;
      }
      setUserDetail(userDetailData);
      return true;
    } catch (e) {
      console.error(e);
      setError(true);
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line

  return { userDetail, loading, error, updateUserDetails, uploadProfilePicture };
};

export default useGetUserDetails;
