import { Attachment } from 'components/Sections/Section';

export enum FieldType {
  STRING = 'string',
  LONG_STRING = 'long_string',
  SELECT = 'select',
  FILE_DROPZONE = 'file_dropzone',
  HTML_CONTENT = 'html_content',
  NUMERIC = 'number'
}

export type FormState = Record<string, InputState>;

export interface InputState {
  value: string | File[];
  required?: boolean;
  maxLength?: number;
  error?: boolean;
  validationErrorKey?: string;
  validationErrorMessage?: string;
  localizationKey?: string;
  infoLocalizationKey?: string;
  type: FieldType;
  disabled?: boolean;
  questionId?: string;
  additionalInfo?: string;
}

export interface SelectInputState extends InputState {
  options: SelectItem[];
  defaultValue?: string;
  onSelectOption?: (value: string, formState: FormState) => FormState;
}

export interface HtmlInputState extends InputState {
  preview?: string;
  attachments?: Attachment[];
}

export interface SelectItem {
  value: string | number;
  name: string;
}
