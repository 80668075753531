import { useContext } from 'react';
import styles from './styles';
import { SectionContext } from 'context/Section/SectionContext';
import { PhaseContext } from 'context/Phase/PhaseContext';
import { Alert } from '@material-ui/lab';
import useT from 'hooks/useT';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, GridSize } from '@material-ui/core';
import { SectionWithContent } from 'utils/sections';
import EditableContent from 'components/EditableContent/EditableContent';
import useBasePath from 'hooks/useBasePath';
import { IconKeys } from '@groupbuilderoy/gb-components-library';
import { SectionType } from 'components/Sections/Section';

export default function Phase() {
  const history = useHistory();
  const classes = styles();
  const { sectionState } = useContext(SectionContext);
  const { phaseState } = useContext(PhaseContext);
  const params = useParams<{ sectionId?: string }>();
  const t = useT();
  const basePath = useBasePath();

  const newSectionUrl = `${basePath}/phase/${phaseState.selectedPhase?.id}/section/create`;

  if (params.sectionId) return null;

  const renderContent = () => {
    if (!sectionState.loading && (!phaseState.phases.length || !sectionState.sections.length)) {
      const textKey = !phaseState.phases.length ? 'noPhases' : 'noSections';

      return (
        <Alert severity='info' variant='outlined'>
          {t('alert.' + textKey)}
        </Alert>
      );
    }

    return (
      <Grid container spacing={3}>
        {sectionState.sections?.map((section: SectionWithContent, index: number) => {
          if (!section.card) return null;
          const width = section.type === 7 ? 3 : section.width;
          const lg = (section.width ? width * 4 : 4) as GridSize;
          const md = section.width > 1 ? 12 : 6;
          return (
            <Grid
              item
              key={index}
              xs={12}
              md={md}
              lg={lg}
              className={section.type === SectionType.CUSTOM_CONTENT ? undefined : classes.section}
            >
              {section.card}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <EditableContent
      editCaption={t('newSection')}
      iconKey={IconKeys.add}
      onEdit={phaseState.phases.length ? () => history.push(newSectionUrl) : undefined}
    >
      {renderContent()}
    </EditableContent>
  );
}
