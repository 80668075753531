import { postFormtItem, putFormItem } from 'axiosInstances';
import { useContext } from 'react';
import styles from './styles';
import { Alert, Color } from '@material-ui/lab';
import Progress from 'components/Progress/Progress';
import useT from 'hooks/useT';
import { AppContext } from 'context/App/AppContext';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import Form from 'components/Form/Form';
import { FieldType, FormState } from 'components/Form/interfaces';
import { FormsContext } from 'context/Forms/FormsContext';
import { ProjectContext } from 'context/Project/ProjectContext';

export default function CreateOrViewForm() {
  const classes = styles();
  const t = useT();
  const { appState } = useContext(AppContext);
  const { apartmentState } = useContext(ApartmentContext);
  const { projectState } = useContext(ProjectContext);
  const { selectedApartment } = apartmentState;
  const { selectedProject } = projectState;
  const { formsState } = useContext(FormsContext);

  const selectedForm = formsState.selectedForm;

  // add title and description to form fields
  const nameAndDescriptionFields = {
    '0': {
      value:
        formsState.selectedForm?.name || formsState.selectedFormType?.defaultFilledFormName || '',
      additionalInfo: '',
      localizationKey: t('forms.title'),
      type: FieldType.STRING,
      required: true,
      validationErrorKey: 'validation.fieldCannotBeEmpty'
    }
  };

  const parsedFilledFormFields = formsState.selectedFormType?.questions.reduce((acc, question) => {
    const answer = selectedForm?.answers.find((answer) => answer.questionId === question.id);
    acc[(question.id + 2).toString()] = {
      value: answer?.value || '',
      additionalInfo: question.description,
      localizationKey: question.question,
      type: question.answerType === FieldType.NUMERIC ? FieldType.NUMERIC : FieldType.STRING,
      questionId: question.id,
      required: question.isMandatory,
      validationErrorKey: 'validation.fieldCannotBeEmpty'
    };
    return acc;
  }, {} as any);

  const additionalFields = {
    additionalNotes: {
      value: formsState.selectedForm?.note || '',
      additionalInfo: '',
      localizationKey: t('forms.additionalNotes'),
      type: FieldType.LONG_STRING
    }
  };

  const renderAlert = (messageKey: string, severity: Color) => {
    const message = t('checklist.alert.' + messageKey);
    return (
      <Alert className={classes.formAlert} variant='outlined' severity={severity}>
        {message}
      </Alert>
    );
  };

  if (!selectedApartment || apartmentState.loading) return <Progress />;
  else if (!selectedApartment?.rooms || !selectedApartment.rooms?.length)
    return renderAlert('noRooms', 'info');

  return (
    <Form
      isQuestionnarie={true}
      localizationKeys={{
        title: '',
        submit: selectedForm ? 'update' : 'submit',
        submitSuccess: selectedForm ? 'forms.alert.updateSuccess' : 'forms.alert.creationSuccess',
        submitError: selectedForm ? 'forms.alert.updateFailed' : 'forms.alert.creationFailed'
      }}
      localizationOverrides={{
        title: `${formsState.selectedFormType?.name}`,
        description: formsState.selectedFormType?.description || undefined
      }}
      fields={{ ...nameAndDescriptionFields, ...parsedFilledFormFields, ...additionalFields }}
      onSubmit={async (filledForm: FormState) => {
        const { organisationId, tenantId } = appState.startParameters;
        if (
          organisationId &&
          tenantId &&
          selectedProject &&
          selectedApartment &&
          formsState.selectedFormType
        ) {
          const answers = Object.values(filledForm);
          const filteredAnswers = answers.slice(1, -1);
          if (selectedForm) {
            await putFormItem({
              token: String(appState.token),
              organisationId: organisationId,
              tenantId: tenantId,
              projectId: selectedProject?.id,
              apartmentId: selectedApartment?.id.toString(),
              filledFormId: selectedForm.id,
              fields: {
                name: filledForm['0'].value,
                description: formsState.selectedFormType.description,
                formId: formsState.selectedFormType.id,
                note: filledForm[Object.keys(filledForm)[Object.keys(filledForm).length - 1]].value,
                answers: filteredAnswers.map((answer) => {
                  return {
                    questionId: answer.questionId,
                    value: answer.value
                  };
                })
              }
            });
          } else {
            await postFormtItem({
              token: String(appState.token),
              organisationId: organisationId,
              tenantId: tenantId,
              projectId: selectedProject?.id,
              apartmentId: selectedApartment?.id.toString(),
              fields: {
                name: filledForm['0'].value,
                description: formsState.selectedFormType.description,
                formId: formsState.selectedFormType.id,
                note: filledForm[Object.keys(filledForm)[Object.keys(filledForm).length - 1]].value,
                answers: filteredAnswers.map((answer) => {
                  return {
                    questionId: answer.questionId,
                    value: answer.value
                  };
                })
              }
            });
          }
          // dispatch({
          //   ...formsState,
          //   selectedForm: null,
          //   selectedFormType: null
          // });
        }
      }}
    />
  );
}
