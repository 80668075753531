import { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  MenuItem
} from '@material-ui/core';
import useStyles from './styles';
import useT from 'hooks/useT';
import {
  EventType,
  ReservationType,
  AppointmentDays,
  AvailableSlots,
  appointmentSlots,
  InsertedReservation,
  DialogType,
  LocalFormatDateTimeType
} from '../../interfaces';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
// import { format } from 'date-fns';
import CalendarEventSlotSelector from './CalendarEventSlotSelector';
// import { getDateFnsLocale } from 'i18n';
import EventQuestions from './EventQuestions';
import { localFormatDateTime } from 'utils/localFormatDateTime';

interface OwnerDialogInterface {
  handleCloseDialog: () => void;
  openDialog: boolean;
  dialogType?: DialogType;
  event: EventType;
  addNewReservation: any;
}

const BookAppointmentDialog = ({
  handleCloseDialog,
  openDialog,
  event,
  addNewReservation
}: OwnerDialogInterface) => {
  const { apartmentState } = useContext(ApartmentContext);
  const selectedApartment = apartmentState.selectedApartment;
  const classes = useStyles();
  const t = useT();
  const [reservationTypes, setReservationTypes] = useState<ReservationType[]>([]);
  const [availableSlots, setAvailableSlots] = useState<AvailableSlots[] | null>(null);
  const [appointmentDate, setAppointmentDate] = useState<AvailableSlots | null>(null);
  const [reservationType, setReservationType] = useState<ReservationType | null>(null);
  const [appointmentTime, setAppointmentTime] = useState<appointmentSlots | null>(null);
  const [answer1, setAnswer1] = useState<string>('');
  const [answer2, setAnswer2] = useState<string>('');

  useEffect(() => {
    if (event) {
      const types = event.appointmentDays.map(
        (appointment: AppointmentDays) => appointment.reservationType
      );
      const type = types[0];
      setReservationType(type);
      setReservationTypes(types);
      changeAppointmentTimesByReservationType(event, type);
    }
  }, [event]);

  const changeAppointmentTimesByReservationType = (event: EventType, type: ReservationType) => {
    const apppointmentDays = event.appointmentDays;
    const appointmentDaysByType = apppointmentDays.find((a) => a.reservationType.id === type.id);
    if (appointmentDaysByType) {
      setAvailableSlots(
        appointmentDaysByType?.availableSlots.filter(
          (slot, index, self) => index === self.findIndex((s) => s.date === slot.date)
        )
      );
      setAppointmentDate(appointmentDaysByType?.availableSlots[0]);
      setAppointmentTime(appointmentDaysByType?.availableSlots[0].appointmentSlots[0]);
    }
  };

  const handleSave = async () => {
    if (appointmentTime && selectedApartment) {
      const newReservation: InsertedReservation = {
        apartmentId: selectedApartment?.id,
        reservationTypeId: appointmentTime.reservationTypeId,
        resourceAvailabilityPoolId: appointmentTime.resourceAvailabilityPoolId,
        endTime: appointmentTime.endTime,
        numberOfPersonsAttending: 1,
        startTime: appointmentTime.startTime,
        answer1: answer1,
        answer2: answer2
      };
      await addNewReservation(newReservation);
    }

    handleCloseDialog();
  };

  const handleChangeAnswer = (e: any, attribute: string) => {
    if (attribute === 'answer1') {
      setAnswer1(e.target.value);
    } else if (attribute === 'answer2') {
      setAnswer2(e.target.value);
    }
  };

  const handleSelectDate = (date: string) => {
    if (availableSlots == null) return;
    const selectAvailableSlot = availableSlots.find((a) => a.date === date);
    if (!selectAvailableSlot) return;
    setAppointmentDate(selectAvailableSlot);
    setAppointmentTime(selectAvailableSlot.appointmentSlots[0]);
  };

  const handleSelectAppointmentTime = (appointmentString: string) => {
    const appointment = JSON.parse(appointmentString);
    setAppointmentTime(appointment);
  };

  const handleSelectReservationType = (type: string) => {
    const selectedReservationType = reservationTypes.find((r) => r.id.toString() === type);
    if (!selectedReservationType) {
      return;
    }
    setReservationType(selectedReservationType ? selectedReservationType : null);
    changeAppointmentTimesByReservationType(event, selectedReservationType);
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth='xs'>
      <DialogTitle className={classes.titleStyle}>
        {t('calendarSection.addAppointmentTitle')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} style={{ marginBottom: '15px' }}>
          <Grid item xs={12}>
            <strong>{t('calendarSection.apartment')}:</strong> {selectedApartment?.name}
          </Grid>
          {event.name && (
            <Grid item xs={12}>
              <strong>{t('calendarSection.event')}:</strong> {event.name}
            </Grid>
          )}
          {event.description && (
            <Grid item xs={12}>
              <strong>{t('calendarSection.description')}:</strong> {event.description}
            </Grid>
          )}
          {event.registrationDeadline && (
            <Grid item xs={12} style={{ marginBottom: '15px' }}>
              <strong>{t('calendarSection.deadline')}:</strong>{' '}
              {/* {format(new Date(event.registrationDeadline), 'MMMM d, yyyy h:mm a', {
                locale: getDateFnsLocale()
              })} */}
              {localFormatDateTime(
                new Date(event.registrationDeadline),
                LocalFormatDateTimeType.Full
              )}
            </Grid>
          )}
          {reservationType && (
            <Grid item xs={12} style={{ marginBottom: '10px', marginTop: '10px' }}>
              <FormLabel style={{ color: 'black' }}>
                {t('calendarSection.reservationType')}
              </FormLabel>
              <RadioGroup
                name='reservationType'
                value={reservationType.id}
                onChange={(e) => handleSelectReservationType(e.target.value as string)}
              >
                {reservationTypes.map((type, index) => (
                  <FormControlLabel
                    key={index}
                    value={type.id}
                    control={<Radio size='small' color='primary' />}
                    label={`${type.name} (${type.duration} ${t('calendarSection.minutes')})`}
                  />
                ))}
              </RadioGroup>
            </Grid>
          )}
          {availableSlots && (
            <CalendarEventSlotSelector
              title={t('calendarSection.day')}
              value={appointmentDate?.date}
              selectHandling={handleSelectDate}
            >
              {availableSlots.map((slot, index) => (
                <MenuItem key={index} value={slot.date}>
                  {/* {format(new Date(slot.date), 'MMMM d, yyyy', { locale: getDateFnsLocale() })} */}
                  {localFormatDateTime(new Date(slot.date), LocalFormatDateTimeType.Date)}
                </MenuItem>
              ))}
            </CalendarEventSlotSelector>
          )}
          {appointmentDate && (
            <CalendarEventSlotSelector
              title={t('calendarSection.appointmentTime')}
              value={JSON.stringify(appointmentTime)}
              selectHandling={handleSelectAppointmentTime}
            >
              {appointmentDate.appointmentSlots.map((slot, index) => (
                <MenuItem key={index} value={JSON.stringify(slot)}>
                  {/* {format(new Date(slot.startTime), 'HH:mm a', { locale: getDateFnsLocale() })} -{' '}
                  {format(new Date(slot.endTime), 'HH:mm a', { locale: getDateFnsLocale() })} */}
                  {localFormatDateTime(new Date(slot.startTime), LocalFormatDateTimeType.Time)} -{' '}
                  {localFormatDateTime(new Date(slot.endTime), LocalFormatDateTimeType.Time)}
                </MenuItem>
              ))}
            </CalendarEventSlotSelector>
          )}
          <EventQuestions
            event={event}
            handleChange={handleChangeAnswer}
            answer1={answer1}
            answer2={answer2}
          />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.buttonGroup}>
        <Button
          onClick={handleCloseDialog}
          className={classes.button}
          variant='contained'
          color='secondary'
          data-testid='cancel-btn'
        >
          {t('calendarSection.cancel')}
        </Button>

        <Button
          onClick={handleSave}
          className={classes.button}
          variant='contained'
          color='primary'
          data-testid='save-btn'
        >
          {t('calendarSection.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default BookAppointmentDialog;
