import useT from 'hooks/useT';
import { Button, ButtonType } from '@groupbuilderoy/gb-components-library';
import { IconKeys } from '@groupbuilderoy/gb-components-library';
import { useHistory } from 'react-router-dom';
import { useTrackers } from 'hooks/useTrackers';
import { EventAction } from 'services/EventAction';
import { EventCategory } from 'services/EventCategory';
import { AppContext } from 'context/App/AppContext';
import { useContext } from 'react';

interface BackButtonProps {
  customClickHandler?: () => void;
  callback?: () => void;
}

export default function BackButton({ callback, customClickHandler }: BackButtonProps) {
  const { triggerEvent } = useTrackers();
  const t = useT();
  const history = useHistory();
  const { appState } = useContext(AppContext);
  function handleClick() {
    if (callback) {
      callback();
    }

    if (customClickHandler) {
      customClickHandler();
    } else {
      history.goBack();
    }
    triggerEvent({
      action: EventAction.SECTION_BACK_CLICKED,
      category: EventCategory.USER
    });
  }
  if (history.length !== 0) {
    return (
      <Button
        style={{ margin: '1rem 0' }}
        type={
          appState.tenantSettings?.organisationId === 'srv'
            ? ButtonType.contained
            : ButtonType.outlined
        }
        caption={t('back')}
        onClick={() => handleClick()}
        startIcon={IconKeys.longArrowLeft}
      />
    );
  } else return null;
}
