import { useContext, useEffect, useState } from 'react';
import { Grid, Button, DialogActions, DialogContent, DialogTitle, Dialog } from '@material-ui/core';
import useStyles from './styles';
import useT from 'hooks/useT';
import {
  AppointmentDays,
  CalendarReservation,
  DialogType,
  EditedReservation,
  EventType,
  InsertedReservation,
  LocalFormatDateTimeType,
  ReservationType
} from '../../interfaces';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import QuestionField from './QuestionField';
import CalendarEventDateDisplay from './CalendarEventDateDisplay';
import EventQuestions from './EventQuestions';
import { localFormatDateTime } from 'utils/localFormatDateTime';
import ConfirmationDialog from 'components/ApartmentOwners/ConfirmationDialog/ConfirmationDialog';
interface OwnerDialogInterface {
  handleCloseDialog: () => void;
  openDialog: boolean;
  dialogType?: DialogType;
  event: EventType;
  userReservation?: CalendarReservation;
  updateReservationHandling?: any;
  deleteReservationHandling?: any;
  addNewRegistrationHandling?: any;
}

const CalendarEventDialog = ({
  handleCloseDialog,
  openDialog,
  event,
  userReservation,
  updateReservationHandling,
  deleteReservationHandling,
  addNewRegistrationHandling
}: OwnerDialogInterface) => {
  console.log(event);
  const classes = useStyles();
  const t = useT();
  const { apartmentState } = useContext(ApartmentContext);
  const selectedApartment = apartmentState.selectedApartment;
  const [answer1, setAnswer1] = useState<string>('');
  const [answer2, setAnswer2] = useState<string>('');
  const [numberOfPersonsAttending, setNumberOfPersonsAttending] = useState<number>(1);
  const [reservationType, setReservationType] = useState<ReservationType | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    if (userReservation) {
      setAnswer1(userReservation.answer1);
      setAnswer2(userReservation.answer2);
      setNumberOfPersonsAttending(userReservation.numberOfPersonsAttending);
      if (userReservation.reservationTypeId) {
        const types = event.appointmentDays.map(
          (appointment: AppointmentDays) => appointment.reservationType
        );
        setReservationType(types.find((t) => t.id === userReservation.reservationTypeId) ?? null);
      }
    }
  }, [userReservation]);

  const handleSave = async () => {
    if (userReservation && updateReservationHandling) {
      const newReservation: EditedReservation = {
        answer1: answer1,
        answer2: answer2,
        numberOfPersonsAttending: numberOfPersonsAttending
      };
      await updateReservationHandling(userReservation.id, newReservation);
    } else if (
      addNewRegistrationHandling &&
      selectedApartment &&
      event.endTime &&
      event.startTime
    ) {
      const newRegistration: InsertedReservation = {
        apartmentId: selectedApartment?.id,
        endTime: event.endTime,
        numberOfPersonsAttending: numberOfPersonsAttending,
        startTime: event.startTime,
        answer1: answer1,
        answer2: answer2
      };
      await addNewRegistrationHandling(newRegistration);
    }
    handleCloseDialog();
  };

  const handleDelete = async () => {
    if (!userReservation) return;
    await deleteReservationHandling(userReservation.id);
    handleCloseDialog();
  };

  const showDeleteConfirmation = () => {
    setDeleteDialogOpen(true);
  };
  const handleChange = (e: any, attribute: string) => {
    if (attribute === 'answer1') {
      setAnswer1(e.target.value);
    } else if (attribute === 'answer2') {
      setAnswer2(e.target.value);
    } else if (attribute === 'numberOfPersonsAttending') {
      let total = Number(e.target.value);
      if (total > 20) total = 20;
      else if (isNaN(total) || total < 0) total = 0;
      setNumberOfPersonsAttending(total);
    }
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth='xs'>
        <DialogTitle className={classes.titleStyle}>
          {userReservation
            ? t('calendarSection.editReservationTitle')
            : t('calendarSection.addRegistrationTitle')}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} style={{ marginBottom: '15px' }}>
            <Grid item xs={12}>
              <strong>{t('calendarSection.apartment')}:</strong> {selectedApartment?.name}
            </Grid>
            {event.name && (
              <Grid item xs={12}>
                <strong>{t('calendarSection.event')}:</strong> {event.name}
              </Grid>
            )}
            {event.description && (
              <Grid item xs={12}>
                <strong>{t('calendarSection.description')}:</strong> {event.description}
              </Grid>
            )}
            {userReservation?.startTime && userReservation.endTime ? (
              <CalendarEventDateDisplay
                startTime={userReservation.startTime}
                endTime={userReservation.endTime}
                title={'calendarSection.date'}
              />
            ) : event.startTime && event.endTime ? (
              <CalendarEventDateDisplay
                startTime={event.startTime}
                endTime={event.endTime}
                title={'calendarSection.date'}
              />
            ) : (
              <></>
            )}
            {userReservation?.timeCreated && (
              <Grid item xs={12}>
                <strong>{t('calendarSection.createdBy')}:</strong>{' '}
                {userReservation.author.firstName} {userReservation.author.lastName},{' '}
                {/* {format(new Date(userReservation.timeCreated), 'MMMM d, yyyy HH:mm a', {
                  locale: getDateFnsLocale()
                })} */}
                {localFormatDateTime(
                  new Date(userReservation.timeCreated),
                  LocalFormatDateTimeType.Full
                )}
              </Grid>
            )}
            {reservationType && (
              <Grid item xs={12}>
                <strong>{t('calendarSection.reservationType')}:</strong>{' '}
                {`${reservationType.name} (${reservationType.duration} ${t(
                  'calendarSection.minutes'
                )})`}
              </Grid>
            )}
            {event.type === 'TYPE_INVITE_ONLY' && (
              <Grid item xs={12} style={{ marginBottom: '10px', marginTop: '10px' }}>
                <QuestionField
                  type='number'
                  question={t('calendarSection.attendingNumber')}
                  answer={numberOfPersonsAttending}
                  handleChangeAnswer={handleChange}
                  fieldName='numberOfPersonsAttending'
                  isRequired={true}
                  inputProps={{
                    inputProps: { min: 0, max: 20 }
                  }}
                />
              </Grid>
            )}
            <EventQuestions
              event={event}
              handleChange={handleChange}
              answer1={answer1}
              answer2={answer2}
            />
          </Grid>
        </DialogContent>
        <DialogActions className={classes.buttonGroup}>
          {userReservation ? (
            <Button
              onClick={showDeleteConfirmation}
              variant='contained'
              color='secondary'
              data-testid='delete-btn'
              className={classes.button}
            >
              {t('calendarSection.delete')}
            </Button>
          ) : (
            <Button
              onClick={handleCloseDialog}
              className={classes.rightMargin}
              variant='contained'
              color='secondary'
              data-testid='cancel-btn'
            >
              {t('calendarSection.cancel')}
            </Button>
          )}
          <div className={classes.rightButtonContainer}>
            {userReservation && (
              <Button
                onClick={handleCloseDialog}
                className={classes.rightMargin}
                variant='contained'
                color='secondary'
                data-testid='cancel-btn'
              >
                {t('calendarSection.cancel')}
              </Button>
            )}
            <Button
              onClick={handleSave}
              className={classes.button}
              variant='contained'
              color='primary'
              data-testid='save-btn'
            >
              {t('calendarSection.save')}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        dialogProps={{
          open: deleteDialogOpen,
          onClose: () => setDeleteDialogOpen(false)
        }}
        onHandling={handleDelete}
      />
    </>
  );
};
export default CalendarEventDialog;
