import {
  ActionButton,
  Button,
  ButtonType,
  Icon,
  IconKeys
} from '@groupbuilderoy/gb-components-library';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import useStyles from './styles';
import useT from 'hooks/useT';

interface GBModalProps {
  isOpen: boolean;
  title: string;
  description: string;
  onApprove?: () => void;
  onCancel?: () => void;
  approving?: boolean;
  approveText?: string;
  cancelText?: string;
  icon?: JSX.Element;
  closeModal: () => void;
  disableBackdropClick?: boolean;
  showCloseIcon?: boolean;
}

function GBModal({
  isOpen,
  title,
  description,
  onApprove,
  onCancel,
  approving = false,
  approveText,
  cancelText,
  icon,
  closeModal,
  disableBackdropClick = false,
  showCloseIcon = false
}: GBModalProps) {
  const classes = useStyles();
  const t = useT();

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason == 'backdropClick' && !disableBackdropClick) closeModal();
      }}
    >
      <DialogContent className={classes.content}>
        {showCloseIcon && (
          <Icon
            data-testid='close-icon'
            onClick={closeModal}
            size={18}
            iconKey={IconKeys.close}
            className={classes.closeIcon}
          />
        )}
        {icon && <div className={classes.iconContainer}>{icon}</div>}

        <DialogTitle className={classes.title}>{title}</DialogTitle>
        <Typography variant='body2' className={classes.description}>
          {description}
        </Typography>
        {(onApprove || onCancel) && (
          <DialogActions className={classes.actions}>
            {onCancel && (
              <Button
                color='primary'
                type={ButtonType.outlined}
                onClick={onCancel}
                caption={cancelText || t('modal.close')}
              />
            )}
            {onApprove && (
              <ActionButton onClick={onApprove} loading={approving}>
                {approveText || t('modal.approve')}
              </ActionButton>
            )}
          </DialogActions>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default GBModal;
