import BackButton from 'components/BackButton/BackButton';
import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useStyles from './styles';
import { IconKeys, StyledPaper } from '@groupbuilderoy/gb-components-library';
import { SectionContext } from 'context/Section/SectionContext';
import { SectionType } from 'components/Sections/Section';
import EditableContent from 'components/EditableContent/EditableContent';
import useBasePath from 'hooks/useBasePath';
import useT from 'hooks/useT';

const editableSections = [SectionType.CUSTOM_CONTENT];

export default function SectionPage() {
  const t = useT();
  const history = useHistory();
  const basePath = useBasePath();
  const classes = useStyles();
  const { sectionId } = useParams<{ sectionId?: string }>();
  const { sectionState } = useContext(SectionContext);

  const section = sectionState.sections?.find((section) => String(section.id) === sectionId);
  const editPath = `${basePath}/phase/${section?.phaseId}/section/${section?.id}/edit`;

  if (!section?.page) return null;

  const Section = (
    <StyledPaper style={{ width: '100%' }}>
      <div className={classes.section}>
        {sectionState.showBackButton && <BackButton />}
        {section.page}
      </div>
    </StyledPaper>
  );

  if (section?.type && editableSections.includes(section.type))
    return (
      <EditableContent
        editCaption={t('edit')}
        iconKey={IconKeys.edit}
        onEdit={() => {
          history.push(editPath);
        }}
      >
        {Section}
      </EditableContent>
    );
  else return Section;
}
