import { Grid } from '@material-ui/core';
import { ListEvent } from './List/ListEvent/ListEvent';
import styles from './styles';
import CalendarSection from './CalendarSection';
import { useContext } from 'react';
import useGetCalendarEventsSection from 'hooks/useGetCalendarEvents';
import { CalendarEventsContext } from 'context/CalendarEvents/CalendarEventsContext';
import Progress from 'components/Progress/Progress';

interface Props {
  section: CalendarSection;
}

const CalendarEventsPage = ({ section }: Props) => {
  const classes = styles();
  const { loading } = useGetCalendarEventsSection(section.id.toString());
  const { calendarEventsState } = useContext(CalendarEventsContext);
  const events = calendarEventsState.CalendarEventsSection?.events;
  if (loading) return <Progress />;
  return (
    <Grid container direction='column'>
      <Grid item className={classes.content}>
        {events &&
          events.map((e: any) => (
            <ListEvent key={e.id} event={e} user={calendarEventsState.user} />
          ))}
      </Grid>
    </Grid>
  );
};

export default CalendarEventsPage;
