import axios, { AxiosResponse } from 'axios';
import { TenantSettings } from 'context/App/types';
import { BaseResponse } from './types';

import { createConfig, createPrivatePath } from './utils';
import { Phase } from 'context/Phase/interfaces';
import { Apartment } from 'context/Apartment/interfaces';
import { Project } from 'context/Project/interfaces';
import { EditedReservation, InsertedReservation } from 'components/Sections/Calendar/interfaces';
import { UserDetailsFormData } from 'routes/UserDetails/UserDetails';

export const instance = axios.create(createConfig({}));

export const getTenantSettings = async (
  organisationId: string,
  tenantId: string,
  token?: string
): Promise<TenantSettings> => {
  try {
    const { error, data } = (await instance.get(
      createPrivatePath({ organisationId, tenantId }) + `/settings`,
      createConfig({ token })
    )) as BaseResponse;

    if (error || !data.data) throw Error('Failed to get tenant settings');

    return data.data;
  } catch (e) {
    throw e;
  }
};

export const getProjects = async (token: string, projectId: string): Promise<any> => {
  try {
    const { data, error } = (await instance.get(
      '/v1/private/projects',
      createConfig({ token })
    )) as BaseResponse;

    if (error || !data.data) throw new Error('Unable to fetch projects!');

    const projects = data.data;
    const selectedProject =
      data.data.find((project: Project) => String(project.id) === projectId) || data.data[0];

    return { projects, selectedProject };
  } catch (e) {
    throw e;
  }
};

interface GetProjectParams {
  organisationId: string;
  tenantId: string;
  projectId: string;
  gbToken: string;
}

export const getProject = ({
  organisationId,
  tenantId,
  projectId,
  gbToken
}: GetProjectParams): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<AxiosResponse<any>> =>
  instance.get(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}`,
    createConfig({ token: gbToken })
  );

interface GetProjectPhasesParams {
  projectId: string;
  gbToken: string;
}

interface GetPhasesResponse {
  data: Phase[];
}

export const getProjectPhases = ({
  projectId,
  gbToken
}: GetProjectPhasesParams): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<AxiosResponse<GetPhasesResponse>> =>
  instance.get(`/v1/private/projects/${projectId}/phases`, createConfig({ token: gbToken }));

interface GetProjectApartmentsAndRoomsParams {
  organisationId: string;
  tenantId: string;
  projectId: string;
  gbToken: string;
}

interface ApartmentsResponse {
  data: Apartment[];
}

export const getProjectApartmentsAndRooms = async ({
  organisationId,
  tenantId,
  projectId,
  gbToken
}: GetProjectApartmentsAndRoomsParams): Promise<AxiosResponse<ApartmentsResponse>> =>
  instance.get(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments-and-rooms`,
    createConfig({ token: gbToken })
  );

interface FetchApartmentInfoParams {
  organisationId: string;
  tenantId: string;
  projectId: string;
  gbToken: string;
  apartmentId: string;
  includeMessageStats: boolean;
}

interface FetchApartmentInfoApartment {
  apartmentId: number;
  id: number;
  ifcGuid: string;
  messageSummary: {
    massMessages: { totalCount: number; newCount: number };
    newCount: number;
    roomMessages: { totalCount: number; newCount: number };
    totalCount: number;
  };
  name: string;
  projectId: number;
  projectIfcguid: string;
}

export const fetchApartmentInfo = async ({
  organisationId,
  tenantId,
  projectId,
  apartmentId,
  gbToken,
  includeMessageStats
}: FetchApartmentInfoParams): Promise<FetchApartmentInfoApartment> => {
  try {
    const { error, data } = (await instance.get(
      createPrivatePath({ organisationId, tenantId, projectId }) + `/apartments/${apartmentId}`,
      createConfig({ token: gbToken, params: { rooms: true, includeMessageStats } })
    )) as BaseResponse;

    if (error || !data.data) throw new Error('Unable to fetch apartment info');

    return data.data;
  } catch (e) {
    throw e;
  }
};

interface GetSectionsForPhaseParams {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  phaseId: string;
}

export const getSectionsForPhase = ({
  phaseId,
  organisationId,
  tenantId,
  gbToken
}: GetSectionsForPhaseParams): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<any> => {
  try {
    return instance.get(
      `/v6/private/organisations/${organisationId}/tenants/${tenantId}/sections`,
      createConfig({ token: gbToken, params: { phaseId } })
    );
  } catch (e) {
    throw e;
  }
};

interface GetAttachmentsByProjectIdParams {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  projectId: string;
}

export const getAttachmentsByProjectId = ({
  organisationId,
  tenantId,
  projectId,
  gbToken
}: GetAttachmentsByProjectIdParams): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<AxiosResponse<any>> =>
  instance.get(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/attachments/`,
    createConfig({ token: gbToken })
  );

interface GetDeadlinesParams {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  projectId: string;
  apartmentId: string;
}

export const getDeadlines = async ({
  gbToken,
  organisationId,
  tenantId,
  apartmentId,
  projectId
}: GetDeadlinesParams) => {
  const response = await instance.get(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartmentId}/bundle-deadline-events`,
    createConfig({ token: gbToken })
  );
  return response.data.data;
};

interface GetApartmentsParams {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  projectId: string;
  apartmentId: string;
}

export const getApartments = async ({
  gbToken,
  organisationId,
  tenantId,
  projectId,
  apartmentId
}: GetApartmentsParams): Promise<any> => {
  try {
    const { error, data } = (await instance.get(
      createPrivatePath({ organisationId, tenantId, projectId }) + `/cj/apartments/list`,
      createConfig({ token: gbToken })
    )) as BaseResponse;

    if (error || !data.data) throw new Error('Unable to fetch apartments');

    const apartments: Apartment[] = data.data.map((apartment: Apartment) => ({
      id: apartment.id,
      name: apartment.name
    }));

    const selectedApartment =
      apartments.find((apartment: Apartment) => String(apartment.id) === apartmentId) ||
      apartments[0];

    return { apartments, selectedApartment };
  } catch (e) {
    throw e;
  }
};

interface GetUserDetailParams {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  auth0Id: string;
  email?: string;
  userDetailData?: UserDetailsFormData;
}

export const getUserDetail = async ({
  gbToken,
  organisationId,
  tenantId,
  auth0Id
}: GetUserDetailParams) => {
  const { data } = await instance.get(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/users/${auth0Id}`,
    createConfig({ token: gbToken })
  );
  return data.data.user;
};

export const getUserDetailPicture = async ({
  gbToken,
  organisationId,
  tenantId,
  auth0Id
}: GetUserDetailParams): Promise<Blob> => {
  const response = await instance.get(
    `v6/private/organisations/${organisationId}/tenants/${tenantId}/users/${auth0Id}/picture`,
    {
      ...createConfig({ token: gbToken }),
      responseType: 'blob'
    }
  );
  return response.data;
};

export const postUserDetailPicture = async ({
  gbToken,
  organisationId,
  tenantId,
  auth0Id,
  file
}: {
  gbToken: string;
  organisationId: string;
  tenantId: string;
  auth0Id: string;
  file: File;
}): Promise<void> => {
  const formData = new FormData();
  formData.append('image', file);
  const { data } = await instance.post(
    `v6/private/organisations/${organisationId}/tenants/${tenantId}/users/${auth0Id}/picture`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${gbToken}`,
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  return data.data;
};

export const editUserDetail = async ({
  gbToken,
  organisationId,
  tenantId,
  auth0Id,
  email,
  userDetailData
}: GetUserDetailParams) => {
  const dataBody = {
    email: email,
    userProfileInfo: {
      firstName: userDetailData?.firstName,
      lastName: userDetailData?.lastName,
      email: userDetailData?.email,
      phoneNo: userDetailData?.phoneNumber,
      streetAddress: userDetailData?.streetAddress,
      city: userDetailData?.city,
      state: userDetailData?.stateProvince,
      zipCode: userDetailData?.postCode,
      country: userDetailData?.country
    }
  };
  const { data } = await instance.put(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/users/${auth0Id}`,
    dataBody,
    createConfig({ token: gbToken })
  );
  return data.data.user;
};

export interface AddUserDetailParams {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  email: string;
  projects: MetaDataProject[];
  userDetailData: {
    firstName: string;
    lastName: string;
    phoneNo: string;
  };
}

export interface MetaDataProject {
  id: number;
  apartments: MetaDataApartment[];
}

export interface MetaDataApartment {
  id: number;
  level: number;
}

export const getUserDetailByEmail = async ({
  gbToken,
  organisationId,
  tenantId,
  email
}: {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  email: string;
}) => {
  try {
    const response = await instance.get(
      `/v6/private/organisations/${organisationId}/tenants/${tenantId}/users/email/${email}`,
      createConfig({ token: gbToken })
    );
    if (!response || response.data.error) {
      return null;
    }
    return response.data.data;
  } catch (e: any) {
    console.error(`Error occurred while fetching user details: ${e.message}`);
    return null;
  }
};

export const addUserDetail = async ({
  gbToken,
  organisationId,
  tenantId,
  projects,
  email,
  userDetailData
}: AddUserDetailParams) => {
  const dataBody = {
    email: email,
    userProfileInfo: userDetailData,
    userRoleMetadata: {
      projects: projects
    }
  };
  const { data } = await instance.post(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/users`,
    dataBody,
    createConfig({ token: gbToken })
  );
  return data.data.user;
};

export const deleteUserDetail = async ({
  gbToken,
  organisationId,
  tenantId,
  auth0Id
}: {
  gbToken: string;
  organisationId: string;
  tenantId: string;
  auth0Id: string;
}) => {
  const { data } = await instance.delete(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/users/${auth0Id}`,
    createConfig({ token: gbToken })
  );
  return data.data.deleted;
};

interface GetFormsParams {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  projectId: string;
  sectionId: string;
  apartmentId: string;
}

export const getForms = async ({
  gbToken,
  organisationId,
  tenantId,
  projectId,
  sectionId,
  apartmentId
}: GetFormsParams) => {
  const response = await instance.get(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/sections/${sectionId}/forms?apartmentId=${apartmentId}`,
    createConfig({ token: gbToken })
  );
  return response.data.data;
};

interface GetCalendarEventsParams {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  projectId: string;
  sectionId: string;
  apartmentId: string;
}

export const getCalendarEvents = async ({
  gbToken,
  organisationId,
  tenantId,
  projectId,
  sectionId,
  apartmentId
}: GetCalendarEventsParams) => {
  const response = await instance.get(
    `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/sections/${sectionId}/calendar-events?apartmentId=${apartmentId}`,
    createConfig({ token: gbToken })
  );
  return response.data.data;
};

export const postCalendarReservation = async ({
  gbToken,
  organisationId,
  tenantId,
  projectId,
  eventId,
  reservationData
}: {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  projectId: string;
  eventId: string;
  reservationData: InsertedReservation;
}) => {
  try {
    const response = (await instance.post(
      `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/calendar-events/${eventId}/register`,
      reservationData,
      createConfig({ token: gbToken })
    )) as BaseResponse;

    if (response.error) throw new Error('Failed to post form item');

    return response.data;
  } catch (e) {
    throw e;
  }
};

export const putCalendarReservation = async ({
  gbToken,
  organisationId,
  tenantId,
  projectId,
  eventId,
  reservationId,
  updatedReservationData
}: {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  projectId: string;
  eventId: string;
  reservationId: string;
  updatedReservationData: EditedReservation;
}) => {
  try {
    const response = (await instance.put(
      `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/calendar-events/${eventId}/reservation/${reservationId}`,
      updatedReservationData,
      createConfig({ token: gbToken })
    )) as BaseResponse;

    if (response.error) throw new Error('Failed to update form item');
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const deleteCalendarReservation = async ({
  gbToken,
  organisationId,
  tenantId,
  projectId,
  eventId,
  reservationId
}: {
  organisationId: string;
  tenantId: string;
  gbToken: string;
  projectId: string;
  eventId: string;
  reservationId: string;
}) => {
  try {
    const response = (await instance.delete(
      `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/calendar-events/${eventId}/reservation/${reservationId}`,
      createConfig({ token: gbToken })
    )) as BaseResponse;

    if (response.error) throw new Error('Failed to delete form item');
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const postFormtItem = async ({
  token,
  organisationId,
  tenantId,
  projectId,
  apartmentId,
  fields
}: {
  token: string;
  organisationId: string;
  tenantId: string;
  projectId: number;
  apartmentId: string;
  fields: any;
}) => {
  const { formId } = fields;
  try {
    const response = (await instance.post(
      `v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartmentId}/forms/${formId}/filled-forms`,
      fields,
      createConfig({ token })
    )) as BaseResponse;

    if (response.error) throw new Error('Failed to post form item');

    return response.data;
  } catch (e) {
    throw e;
  }
};

export const putFormItem = async ({
  token,
  organisationId,
  tenantId,
  projectId,
  apartmentId,
  fields,
  filledFormId
}: {
  token: string;
  organisationId: string;
  tenantId: string;
  projectId: number;
  apartmentId: string;
  fields: any;
  filledFormId: number;
}) => {
  const { formId } = fields;
  try {
    const response = (await instance.put(
      `v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartmentId}/forms/${formId}/filled-forms/${filledFormId}`,
      fields,
      createConfig({ token })
    )) as BaseResponse;

    if (response.error) throw new Error('Failed to post form item');

    return response.data;
  } catch (e) {
    throw e;
  }
};

export const deleteFormItem = async ({
  token,
  organisationId,
  tenantId,
  projectId,
  apartmentId,
  formId,
  filledFormId
}: {
  token: string;
  organisationId: string;
  tenantId: string;
  projectId: string;
  apartmentId: string;
  formId: number;
  filledFormId: number;
}) => {
  try {
    const response = (await instance.delete(
      `/v6/private/organisations/${organisationId}/tenants/${tenantId}/projects/${projectId}/apartments/${apartmentId}/forms/${formId}/filled-forms/${filledFormId}`,
      createConfig({ token })
    )) as BaseResponse;

    if (response.error) throw new Error('Failed to delete form item');

    return response.data;
  } catch (e) {
    throw e;
  }
};
