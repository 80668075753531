import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      justifyContent: 'end'
    },
    button: {
      float: 'right',
      marginBottom: '1rem'
    }
  })
);
