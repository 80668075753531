import SectionForm from 'components/SectionForm/SectionForm';
import { ISection } from 'components/Sections/Section';
import useApi from 'hooks/useApi';

export default function CreateSection() {
  const api = useApi();
  return (
    <SectionForm
      onSubmit={(section: ISection) => {
        delete section.id;
        return api.createSection(section);
      }}
    />
  );
}
