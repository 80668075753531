import { Attachment, ISection, SectionSettings, SectionType } from 'components/Sections/Section';
import { ApiBase, ApiProps } from './ApiBase';

interface CustomerJourneyApiPros extends ApiProps {
  projectId: number;
}

export class CustomerJourneyApi extends ApiBase {
  constructor(props: CustomerJourneyApiPros) {
    super(props);

    this.url += `/v8/private/projects/${props.projectId}/customer-journey`;
  }

  async getAllSections(phaseId: number): Promise<ISection[]> {
    const response = await this.get('/sections', { phaseId });

    return response.data;
  }

  async getSection(sectionId: number, type: SectionType): Promise<ISection> {
    const response = await this.get(`/sections/${sectionId}/${type}`);

    return response.data;
  }

  async createSection(section: ISection): Promise<ISection> {
    const response = await this.postFormData(
      `/sections/${section.type || ''}`,
      this.createFormData(section)
    );

    return response.data;
  }

  async updateSection(section: ISection): Promise<ISection> {
    const response = await this.putFormData(
      `/sections/${section.id}/${section.type || ''}`,
      this.createFormData(section)
    );

    return response.data;
  }

  async updateSectionOrder(id: number, orderNumber: number): Promise<ISection> {
    const response = await this.update(`/sections/${id}`, { orderNumber });
    return response.data;
  }

  async deleteSection(sectionId: number) {
    await this.delete(`/sections/${sectionId}`);
  }

  async getSettings(): Promise<SectionSettings> {
    const response = await this.get('/sections/settings');

    return response.data;
  }

  private createFormData(section: ISection) {
    const formData = new FormData();
    const existingAttachmentIds: number[] = [];

    for (const [key, value] of Object.entries(section)) {
      if (key === 'attachments') {
        (value as Attachment[]).forEach((attachment) => {
          if (attachment.id) existingAttachmentIds.push(attachment.id);
          else if (attachment.file) formData.append('attachments', attachment.file);
        });
      } else {
        formData.append(key, value);
      }
    }

    existingAttachmentIds.forEach((id) => formData.append('existingAttachmentIds[]', String(id)));

    return formData;
  }
}
