import { Button, IconKeys } from '@groupbuilderoy/gb-components-library';
import { useEditingMode } from 'hooks/useEditingMode';
import styles from './styles';

interface EditableContentProps {
  editCaption: string;
  iconKey?: IconKeys;
  children: React.ReactNode;
  onEdit?: () => void;
}

export default function EditableContent({
  editCaption,
  iconKey,
  children,
  onEdit
}: EditableContentProps) {
  const { editingModeEnabled } = useEditingMode();
  const classes = styles();

  if (!editingModeEnabled || !onEdit) return <>{children}</>;

  return (
    <>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          caption={editCaption}
          onClick={onEdit}
          startIcon={iconKey}
        />
      </div>
      {children}
    </>
  );
}
